import React, { useState } from "react";
import { Link, graphql } from "gatsby";

import Layout from "../../components/layout/layout";
import Seo from "../../components/layout/seo";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import SubscriptionBanner from "../../components/blog/banner";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { StaticImage } from "gatsby-plugin-image";
import logo from "/src/images/iso_color.png";
import MyCustomBreadcrumb from "../../components/blog/breadcrumbs";
import { useBreadcrumb } from "gatsby-plugin-breadcrumb";

const BlogIndex = ({ data, location }) => {
  const [visible, setVisible] = useState(3);
  const [enabled, setEnabled] = useState(true);

  const { crumbs } = useBreadcrumb({
    location,
    crumbLabel: "Technology",
    crumbSeparator: " > ",
  });

  const showMoreItems = () => {
    if (data.allMarkdownRemark.totalCount > visible) {
      setVisible((prevValue) => prevValue + 3);
      setEnabled(true);
    } else {
      setEnabled(false);
    }
  };

  let header;
  header = <img style={{ width: "50px" }} src={logo} alt="Logo" />;
  const siteTitle = data.site.siteMetadata?.title || `Title`;
  const posts = data.allMarkdownRemark.nodes;

  if (posts.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <Seo title="All posts | Custom Surgical Blog" />

        <p>
          No blog posts found. Add markdown posts to "content/blog" (or the
          directory you specified for the "gatsby-source-filesystem" plugin in
          gatsby-config.js).
        </p>
      </Layout>
    );
  }

  return (
    <Layout location={location} title={siteTitle} crumbLabel="Technology">
      <Seo title="All posts | Custom Surgical Blog" />

      <div className="viewed" style={{ width: "90%", paddingTop: "1em" }}>
        <div
          style={{
            color: "#00C5B7",
            fontSize: "36px",
            fontWeight: "700",
            marginTop: "2%",
            marginBottom: "0%",
          }}
        >
          Technology
        </div>
        <MyCustomBreadcrumb crumbs={crumbs} />
        <div
          style={{
            fontWeight: "500",
            marginBottom: "1%",
            fontSize: "20px",
          }}
        ></div>
        <hr style={{ margin: 0 }}></hr>
      </div>

      <Box
        sx={{ flexGrow: 1 }}
        marginLeft={{ sm: "72px", xs: "0px" }}
        marginRight={{ sm: "72px", xs: "0px" }}
      >
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          justifyContent={{ xs: "center", sm: "flex-start" }}
        >
          {posts.slice(0, visible).map((post) => {
            const title = post.frontmatter.title || post.fields.slug;

            return (
              <Grid item xs={10} md={4} sm={6} key={post.fields.slug}>
                <article
                  style={{ width: "100%" }}
                  className="post-list-item"
                  itemScope
                  itemType="http://schema.org/Article"
                >
                  <header>
                    <Link to={post.fields.slug} itemProp="url">
                      {" "}
                      <GatsbyImage
                        image={getImage(post.frontmatter.image)}
                        key=""
                        alt={title}
                        imgStyle={{
                          borderRadius: "5px",
                          boxShadow: "1px 1px 1px 2px rgba(0, 0, 0, 0.05)",
                        }}
                        style={{
                          borderRadius: "5px",
                          boxShadow: " 1px 1px 1px 2px rgba(0, 0, 0, 0.05)",
                          height: "270px",
                        }}
                      />
                    </Link>

                    <div className="text_flex_pages">
                      <div className="timer">
                        <StaticImage
                          src="../../images/blog/timer.png"
                          alt="timer image"
                          style={{ height: "20px" }}
                        />
                        <div className="timeread">
                          &#160;{post.timeToRead} mins
                        </div>
                      </div>
                    </div>

                    <h1 className="h2_arc">
                      <Link
                        to={post.fields.slug}
                        itemProp="url"
                        className="link_tech"
                      >
                        {title}
                      </Link>
                    </h1>
                    <div> </div>
                  </header>
                  <section>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: post.frontmatter.description,
                      }}
                      itemProp="description"
                    />
                  </section>
                </article>
              </Grid>
            );
          })}
        </Grid>
      </Box>
      <div style={{ textAlign: "center" }}>
        {enabled ? (
          <button onClick={showMoreItems} className="loadmore">
            Load more
          </button>
        ) : (
          <button onClick={showMoreItems} className="loadmore_disabled">
            Load more
          </button>
        )}
      </div>
      <SubscriptionBanner postName={"technology-page"} tag={"Techonology"} />
    </Layout>
  );
};

export default BlogIndex;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        frontmatter: { tags: { eq: "Technology" } }
        fileAbsolutePath: { regex: "/blog/" }
      }
    ) {
      nodes {
        excerpt
        fields {
          slug
        }
        timeToRead
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          tags
          image {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      totalCount
    }
  }
`;
